import { UserEntity, UserProfile } from '../entities/user.entity';

export abstract class UsersRepositoryPort {
  abstract getCurrentUser(): Promise<UserEntity>;
  abstract signIn(email: string, password: string): Promise<void>;
  abstract signUp(email: string, password: string): Promise<void>;
  abstract signOut(): Promise<void>;
  abstract saveCurrentUserProfile(profile: UserProfile): Promise<UserEntity>;
  abstract setNewPassword(newPassword: string): Promise<void>;
}
