import { DomainException } from '../../../shared/domain/entities/exceptions';

export type UserPrerequisite = 'verify_email' | 'accept_terms' | 'complete_profile';

export type UserErrorCode = 'user-not-found' | 'email-already-used' | 'bad-credentials' | 'password-too-short';

export type Permission =
  | 'https://grammar-drills.com/p/drill:create'
  | 'https://grammar-drills.com/p/drill:update'
  | 'https://grammar-drills.com/p/drill:delete'
  | 'https://grammar-drills.com/p/drill:list';

export const UserErrorMessages: Record<UserErrorCode, string> = {
  'user-not-found': 'User not found',
  'email-already-used': 'Email already used',
  'bad-credentials': 'Bad credentials',
  'password-too-short': 'Password too short',
};

export class UserException extends DomainException {
  constructor(public readonly code: UserErrorCode) {
    super(UserErrorMessages[code]);
  }
}

export class UserProfile {
  constructor(
    public readonly firstname: string,
    public readonly lastname: string,
  ) {}
}

export class UserEntity {
  constructor(
    public readonly id: string,
    public readonly email: string,
    public readonly displayName: string,
    public readonly lang: string,
    public readonly isEmailVerified: boolean,
    public readonly areCurrentTermsAccepted: boolean,
    public readonly permissions: string[],
    public readonly firstname?: string,
    public readonly lastname?: string,
  ) {}

  getUnmetPrerequisites(): UserPrerequisite[] {
    const prerequisites: UserPrerequisite[] = [];

    if (!this.isEmailVerified) {
      prerequisites.push('verify_email');
    }

    // if (!this.areCurrentTermsAccepted) {
    //   prerequisites.push('accept_terms');
    // }

    if (!this.firstname || !this.lastname) {
      prerequisites.push('complete_profile');
    }

    return prerequisites;
  }

  checkPermissions(permissions: Permission[], mode: 'any' | 'all'): boolean {
    if (mode === 'all') {
      return permissions.every((permission) => this.permissions.includes(permission));
    } else {
      return permissions.some((permission) => this.permissions.includes(permission));
    }
  }
}
