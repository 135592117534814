import { inject, Injectable } from '@angular/core';
import { UserException } from '@app/current-user/domain/entities/user.entity';
import { CurrentUserStatePort } from '@app/current-user/domain/ports/current-user.state.port';
import { UsersRepositoryPort } from '@app/current-user/domain/ports/users.repository.port';

@Injectable({
  providedIn: 'root',
})
export class FetchCurrentUserUc {
  private readonly userRepository = inject(UsersRepositoryPort);
  private readonly state = inject(CurrentUserStatePort);

  async execute(): Promise<void> {
    try {
      const user = await this.userRepository.getCurrentUser();
      this.state.setCurrentUser(user);
    } catch (e) {
      if (e instanceof UserException && e.code === 'user-not-found') {
        this.state.setCurrentUser(null);
      } else {
        throw e;
      }
    }
  }
}
