import { Permission, UserEntity } from '../domain/entities/user.entity';
import { CurrentUserVm } from '../domain/view-models/current-user.vm';

export class UserMapper {
  toCurrentUserVm(user: UserEntity) {
    return new CurrentUserVm(
      user.id,
      user.email,
      user.displayName,
      user.lang,
      user.isEmailVerified,
      user.areCurrentTermsAccepted,
      user.permissions as Permission[],
      user.firstname,
      user.lastname,
      user.getUnmetPrerequisites(),
    );
  }
}
