import { inject, Injectable } from '@angular/core';
import { UserEntity } from '@app/current-user/domain/entities/user.entity';
import { CurrentUserStatePort } from '@app/current-user/domain/ports/current-user.state.port';
import { CurrentUserVm } from '@app/current-user/domain/view-models/current-user.vm';
import { UserMapper } from '@app/current-user/mappers/user.mapper';

export class GetCurrentUserResponse {
  private readonly mapper = new UserMapper();

  constructor(private readonly user: UserEntity | null) {}

  present(): CurrentUserVm | null {
    if (!this.user) {
      return null;
    }

    return this.mapper.toCurrentUserVm(this.user);
  }
}

@Injectable({
  providedIn: 'root',
})
export class GetCurrentUserUc {
  private currentUserState = inject(CurrentUserStatePort);

  execute(): GetCurrentUserResponse {
    const currentUser = this.currentUserState.getCurrentUser();
    return new GetCurrentUserResponse(currentUser);
  }
}
