import { inject, Injectable } from '@angular/core';
import { UsersRepositoryPort } from '@app/current-user/domain/ports/users.repository.port';
import { DomainException } from '@app/shared/domain/entities/exceptions';
import { FetchCurrentUserUc } from './fetch-current-user.uc';

export class SignInRequest {
  constructor(
    public readonly email: string,
    public readonly password: string,
  ) {}
}

export class SignInResponse {
  constructor(
    public readonly success: boolean,
    public readonly error?: string,
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class SignInUc {
  private readonly usersRepository = inject(UsersRepositoryPort);
  private readonly fetchCurrentUserUc = inject(FetchCurrentUserUc);

  async execute(request: SignInRequest): Promise<SignInResponse> {
    try {
      await this.usersRepository.signIn(request.email, request.password);
      await this.fetchCurrentUserUc.execute();

      return new SignInResponse(true);
    } catch (e) {
      if (e instanceof DomainException) {
        return new SignInResponse(false, e.message);
      } else {
        return new SignInResponse(false, 'An error occurred');
      }
    }
  }
}
