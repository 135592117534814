import { inject, Injectable } from '@angular/core';
import { CurrentUserStatePort } from '@app/current-user/domain/ports/current-user.state.port';
import { UsersRepositoryPort } from '@app/current-user/domain/ports/users.repository.port';

@Injectable({
  providedIn: 'root',
})
export class SignOutUc {
  private readonly usersRepository = inject(UsersRepositoryPort);
  private readonly currentUserState = inject(CurrentUserStatePort);

  async execute(): Promise<void> {
    await this.usersRepository.signOut();
    this.currentUserState.setCurrentUser(null);
  }
}
