import {
  SecurityChallenge,
  SecurityChallengeChannel,
  SecurityChallengeScope,
} from '../entities/security-challenge.entity';

export abstract class SecurityChallengesRepositoryPort {
  abstract takeChallenge(
    scope: SecurityChallengeScope,
    channel: SecurityChallengeChannel,
    channelIdentifier: string,
  ): Promise<SecurityChallenge>;

  abstract submitChallenge(
    challengeId: SecurityChallenge['id'],
    type: 'one-time-password',
    challengeSubmittal: any,
  ): Promise<{ grant: 'reset_password' | null }>;
}
