import { inject, Injectable } from '@angular/core';
import { UserProfile } from '@app/current-user/domain/entities/user.entity';
import { CurrentUserStatePort } from '@app/current-user/domain/ports/current-user.state.port';
import { UsersRepositoryPort } from '@app/current-user/domain/ports/users.repository.port';

export class SaveUserProfileRequest {
  constructor(public readonly profile: UserProfile) {}
}

export interface SaveUserProfileResponse {
  success: boolean;
  error?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SaveUserProfileUc {
  private readonly usersRepository = inject(UsersRepositoryPort);
  private readonly state = inject(CurrentUserStatePort);

  async execute(request: SaveUserProfileRequest): Promise<SaveUserProfileResponse> {
    const user = await this.usersRepository.saveCurrentUserProfile(request.profile);
    this.state.setCurrentUser(user);

    return {
      success: true,
    };
  }
}
