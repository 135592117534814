import { Injectable } from '@angular/core';
import { SignUpResponse } from '@app/current-user/use-cases/sign-up.uc';
import { SignUpVm } from '../domain/view-models/sign-up.vm';

@Injectable({
  providedIn: 'root',
})
export class SignUpPresenter {
  present(response: SignUpResponse): SignUpVm {
    return new SignUpVm(response.success, response.error);
  }
}
