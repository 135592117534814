import { Injectable } from '@angular/core';
import { SignInResponse } from '@app/current-user/use-cases/sign-in.uc';
import { SignInVm } from '../domain/view-models/sign-in.vm';

@Injectable({
  providedIn: 'root',
})
export class SignInPresenter {
  present(response: SignInResponse): SignInVm {
    return new SignInVm(response.success, response.error);
  }
}
